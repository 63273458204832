<template>
  <div id="cleanRoomWind">
    <el-dialog
      :title="cleanRoomWindFormTitle"
      width="1200px"
      :visible.sync="cleanRoomWindDialogVisible"
      :close-on-click-modal="false"
      @close="cleanRoomWindDialogClose"
    >
      <el-form
        ref="cleanRoomWindFormRef"
        :model="cleanRoomWindForm"
        :rules="cleanRoomWindFormRules"
        label-position="right"
        label-width="110px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="测试仪器" prop="testInstrument">
              <el-input v-model="cleanRoomWindForm.testInstrument" placeholder="请输入测试仪器" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仪器编码" prop="instrumentNo">
              <el-input v-model="cleanRoomWindForm.instrumentNo" placeholder="请输入仪器编码" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="测试日期" prop="testDate">
              <el-date-picker v-model="cleanRoomWindForm.testDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="测试人数" prop="testNumber">
              <el-input v-model="cleanRoomWindForm.testNumber" placeholder="请输入测试人数" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item prop="testBasis">
              <el-input
                v-model="testBasis"
                type="textarea"
                :rows="4"
                clearable
                readonly
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar v-if="cleanRoomWindFormTitle !== '洁净室（区）风速风量、换气次数监测记录详情'">
            <template #buttons>
              <vxe-button @click="insertRowRoomEvent()">
                新增房间检测
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTableRoom"
            border
            resizable
            show-overflow
            keep-source
            :data="roomList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="roomName"
              title="房间名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="efficientPortNo"
              title="高效口编号"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="roomVolume"
              title="房间体积"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="windSpeed"
              title="送风口风速"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="windVolume"
              title="送风口风量"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="times"
              title="换气次数"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="测试结果"
              :edit-render="{name: '$select', options: resultList, props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="cleanRoomWindFormTitle !== '年度内部审核计划详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowRoomEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item>
          <vxe-toolbar v-if="cleanRoomWindFormTitle !== '洁净室（区）风速风量、换气次数监测记录详情'">
            <template #buttons>
              <vxe-button @click="insertRowDeviceEvent()">
                新增设备检测
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTableDevice"
            border
            resizable
            show-overflow
            keep-source
            :data="deviceList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="deviceName"
              title="设备名称"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="deviceNo"
              title="设备编号"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="deviceVolume"
              title="设备体积"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="windSpeed"
              title="测试风速"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="windVolume"
              title="测试风量"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="times"
              title="换气次数"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column
              field="result"
              title="测试结果"
              :edit-render="{name: '$select', options: resultList, props: {clearable: true}}"
            />
            <vxe-table-column
              field="remarks"
              title="备注"
              :edit-render="{name: 'textarea', props: {clearable: true}}"
            />
            <vxe-table-column v-if="cleanRoomWindFormTitle !== '年度内部审核计划详情'" title="操作" width="100">
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowDeviceEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="异常情况及处理" prop="situationTreatment">
              <el-input
                v-model="cleanRoomWindForm.situationTreatment"
                placeholder="请输入异常情况及处理"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="检测人" prop="inspector">
              <el-input v-model="cleanRoomWindForm.inspector" placeholder="请输入检测人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检测时间" prop="detectionDate">
              <el-date-picker v-model="cleanRoomWindForm.detectionDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="复核人" prop="reviewer">
              <el-input v-model="cleanRoomWindForm.reviewer" placeholder="请输入复核人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="复核时间" prop="reviewDate">
              <el-date-picker v-model="cleanRoomWindForm.reviewDate" placeholder="请选择日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="cleanRoomWindDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="cleanRoomWindFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="部门">
        <el-input v-model="searchForm.dept" placeholder="请输入部门" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="cleanRoomWindPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="testInstrument" label="测试仪器" />
      <el-table-column prop="instrumentNo" label="仪器编码" />
      <el-table-column prop="testDate" label="测试日期" />
      <el-table-column prop="testNumber" label="测试人数" />
      <el-table-column prop="situationTreatment" label="异常情况及处理" />
      <el-table-column prop="inspector" label="检测人" />
      <el-table-column prop="detectionDate" label="检测时间" />
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column prop="reviewDate" label="复核时间" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="cleanRoomWindPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addCleanRoomWind, deleteCleanRoomWind, updateCleanRoomWind, selectCleanRoomWindInfo, selectCleanRoomWindList } from '@/api/quality/cleanRoomWind'

export default {
  data () {
    return {
      cleanRoomWindDialogVisible: false,
      cleanRoomWindFormTitle: '',
      cleanRoomWindForm: {
        id: '',
        testInstrument: '',
        instrumentNo: '',
        testDate: '',
        testNumber: '',
        situationTreatment: '',
        reviewer: '',
        reviewDate: '',
        approver: '',
        approveTime: '',
        roomJson: '',
        deviceJson: ''
      },
      cleanRoomWindFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      cleanRoomWindPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        dept: ''
      },
      deviceList: [],
      roomList: [],
      testBasis: '测试依据： 《洁净室（区）环境监测管理规程》、 YY0033-2000《无菌医疗器具生产管理规范》\n换气次数计数公式：n=（L1+L2+···Ln）/A \n式中：n为换气次数(次/h);Li为房间各送风口风量（m3/h）：A为房间体积（m3）\n可接受标准：100，000级≥15次/h：10，000级≥20次/h：100级：≥0.3m/s',
      resultList: [
        { label: '符合规定', value: '1' },
        { label: '不符合规定', value: '2' }
      ]
    }
  },
  created () {
    selectCleanRoomWindList(this.searchForm).then(res => {
      this.cleanRoomWindPage = res
    })
  },
  methods: {
    cleanRoomWindDialogClose () {
      this.$refs.cleanRoomWindFormRef.resetFields()
      this.deviceList = []
      this.roomList = []
      this.cleanRoomWindForm.id = ''
    },
    cleanRoomWindFormSubmit () {
      if (this.cleanRoomWindFormTitle === '洁净室（区）风速风量、换气次数监测记录详情') {
        this.cleanRoomWindDialogVisible = false
        return
      }
      this.$refs.cleanRoomWindFormRef.validate(async valid => {
        if (valid) {
          this.cleanRoomWindForm.roomJson = JSON.stringify(this.$refs.xTableRoom.getTableData().tableData)
          this.cleanRoomWindForm.deviceJson = JSON.stringify(this.$refs.xTableDevice.getTableData().tableData)
          if (this.cleanRoomWindFormTitle === '新增洁净室（区）风速风量、换气次数监测记录') {
            await addCleanRoomWind(this.cleanRoomWindForm)
          } else if (this.cleanRoomWindFormTitle === '修改洁净室（区）风速风量、换气次数监测记录') {
            await updateCleanRoomWind(this.cleanRoomWindForm)
          }
          this.cleanRoomWindPage = await selectCleanRoomWindList(this.searchForm)
          this.cleanRoomWindDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.cleanRoomWindFormTitle = '新增洁净室（区）风速风量、换气次数监测记录'
      this.cleanRoomWindDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteCleanRoomWind(row.id)
        if (this.cleanRoomWindPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.cleanRoomWindPage = await selectCleanRoomWindList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.cleanRoomWindFormTitle = '修改洁净室（区）风速风量、换气次数监测记录'
      this.cleanRoomWindDialogVisible = true
      this.selectCleanRoomWindInfoById(row.id)
    },
    handleInfo (index, row) {
      this.cleanRoomWindFormTitle = '洁净室（区）风速风量、换气次数监测记录详情'
      this.cleanRoomWindDialogVisible = true
      this.selectCleanRoomWindInfoById(row.id)
    },
    selectCleanRoomWindInfoById (id) {
      selectCleanRoomWindInfo(id).then(res => {
        this.cleanRoomWindForm.id = res.id
        this.cleanRoomWindForm.testInstrument = res.testInstrument
        this.cleanRoomWindForm.instrumentNo = res.instrumentNo
        this.cleanRoomWindForm.testDate = res.testDate
        this.cleanRoomWindForm.testNumber = res.testNumber
        this.cleanRoomWindForm.situationTreatment = res.situationTreatment
        this.cleanRoomWindForm.inspector = res.inspector
        this.cleanRoomWindForm.inspector = res.inspector
        this.cleanRoomWindForm.reviewer = res.reviewer
        this.cleanRoomWindForm.reviewDate = res.reviewDate
        this.deviceList = res.deviceList
        this.roomList = res.roomList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectCleanRoomWindList(this.searchForm).then(res => {
        this.cleanRoomWindPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectCleanRoomWindList(this.searchForm).then(res => {
        this.cleanRoomWindPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectCleanRoomWindList(this.searchForm).then(res => {
        this.cleanRoomWindPage = res
      })
    },
    async insertRowRoomEvent (row) {
      let { row: newRow } = await this.$refs.xTableRoom.insertAt({}, -1)
      await this.$refs.xTableRoom.setActiveRow(newRow)
    },
    removeRowRoomEvent (row) {
      this.$refs.xTableRoom.remove(row)
    },
    async insertRowDeviceEvent (row) {
      let { row: newRow } = await this.$refs.xTableDevice.insertAt({}, -1)
      await this.$refs.xTableDevice.setActiveRow(newRow)
    },
    removeRowDeviceEvent (row) {
      this.$refs.xTableDevice.remove(row)
    }
  }
}
</script>

<style>
</style>
